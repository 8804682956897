
/* Container */
.footer-container {
  padding: 20px;
  background-color: #018d91;
  color: white;
  text-align: center;
  width: 100%;
}

/* Logo Section */
.footer-logo img {
  width: 120px;
  margin: 20px auto;
}

/* Links Section */
.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-link {
  color: white;
  margin: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #00FFFF;
}

/* Follow Us Text */
.footer-follow {
  font-size: 18px;
  margin-top: 20px;
}

/* Social Icons Section */
.footer-social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.footer-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer-icon:hover {
  transform: scale(1.1);
}


.faqs-card{
  max-width: 70%;
  margin: auto;
  padding: 40px 25px ;
  box-shadow: 0px 0px 14px 5px rgba(0, 255, 255, 0.5);
  background: #00000066;
  border-radius: 57px;
}
.faq-wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.faq-questions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-text{
  font-size: 26px;
  font-weight: 600;
}
.faq-heading{
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
}
.questions-head{
 color: rgba(0, 255, 255, 1);

}
@media screen and (max-width: 768px){
  .faq-heading{
    font-size: 24px;
    padding: 0 10px;
  }
  .faqs-card{
    max-width: 88%;
  }
  .faq-text{
    font-size: 16px;
  }
}


.faq-answer{
  font-size: 15px !important;
    margin-top:  5px !important;

}


@media screen and (max-width: 768px){
 .mobile-View-web{
   display: none;
 }
}


@media screen and (min-width: 768px){
  .mobile-View{
    display: none;
    margin: 20px 15px;
  }
 }

.funding-hero {
     text-align: center;
    padding: 3rem 0;
     }
  
  .funding-hero-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .funding-hero-text {
    width: 50%;
    padding: 0 0.5rem;
    margin-bottom: 2rem;
  }
  
  .funding-hero-text p {
    font-size: 1.125rem; /* equivalent to md:text-lg */
    color: white;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 3px;
  }
  
  .funding-hero-title {
    color: white;
    font-weight: bold;
    font-size: 2.25rem; /* equivalent to text-3xl */
    margin-top: 0.75rem;
    margin-bottom: 1.25rem;
    line-height: 1.2;
  }
  
  .funding-hero-subtitle {
    color: white;
    font-size: 0.875rem; /* equivalent to max-md:text-sm */
    font-style: italic;
    margin-bottom: 1.75rem;
    letter-spacing: 3px;
  }
  

  .hero-btn-wrapper{
    margin-top: 12px;
    display: flex;
    gap: 27px;
    align-items: center;
  }
  .whitepaper-btn{
    padding: 16px 60px;
    border-radius: 8px;
    background-color: #007aff;
    border-color: #fff !important;
    box-shadow: 0 6px #00000040
  }
  .audit-btn{
    padding: 16px 60px;
    border-radius: 8px;
    background-color: #f7cb46;
    border-color: #333 !important;
    box-shadow: 0 6px #00000040;
    color: black;
    font-weight: 700;
  }
  .card-container {
    max-width: 400px;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #333333;
    margin: 0 auto;
    padding: 20px; /* Add padding to the container to create space inside */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }
  
  .next-price-text {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px; /* Adjust bottom margin for space between text and card */
  }
  
  .time-card {
    color: white;
    padding: 10px 12px;
    background: #172B24;
    border-radius: 12px;
    display: flex;
    flex: row;
    gap: 2rem;
    justify-content:center ;
  }
  
  .time-duration {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center; /* Center the text inside the time-duration */
    position: relative; /* Allow positioning of the colon */
  }
  
  .funding-hero-image {
    width: 50%;
    padding: 0 0.5rem;
  }
  .hr-lines{
    margin-top: 15px;
    position: relative;
    text-align: center;
  }
  .hr-lines:before{
    content:" ";
    display: block;
    height: 2px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 0;
    background: red;
  }
  .hr-lines:after{
    content:" ";
    height: 2px;
    width: 80px;
    background: red;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  }  
  .currency-btn{
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 10px;
  }
  .eth-btn{

    background-color: blue;
    color: white;
    border-radius: 4px;
  
  }
  .btc-btn{
 
    color: white;
    border-radius: 4px;
    border: 1px solid black;
  }
  .wallet-btn{
    min-width: 110px;
    padding: 10px 30px;
    border-radius: 8px;
    border: 2px solid #007AFF !important;
    margin-top: 10px;
    text-align: center;
    line-height: 19.2px;
    letter-spacing: 1px;
  }  
  
  
  @media (max-width: 768px) {
    .funding-hero {
      padding: 4rem 0;
    }
    .funding-hero-container{
      flex-direction: column;
    }
    .funding-hero-text{
      width: 100%;
    }
    .funding-hero-image{
      width: 100%;
    }
    .hero-btn-wrapper{
     flex-direction: column;
     margin-top: 2rem;
    }
  
    .funding-hero-text p {
      font-size: 1.125rem;
    }
  
    .funding-hero-title {
      font-size: 3.75rem; /* equivalent to md:text-6xl */
    }
    .time-card{
      gap: 1rem;
    }
    .hr-lines:before{
     
      width: 40px;
     
    }
    .hr-lines:after{
      
      width: 40px;
   
    } 
    /* .btc-btn,
    .eth-btn{
      padding: 10px 46px;
    }  */
    
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');




* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;


}

body {
  font-family: 'Open Sans';
  /* background: #000; */
  color: #FFFFFF;
}


p {
  /* margin-bottom: 0 !important; */
}

a, a:hover {
  text-decoration: none;
  color: #FFFFFF;
}

input:is(:active, :focus) {
  outline: none;
}


.wrapper {
  transition: padding .3s ease, max-width .3s ease;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1140px;
}

.row,
.full {
  width: 100% !important;
}

.box {
  padding: 60px 0;
}

@media screen and (min-width: 1400px) {
  .wrapper {
    max-width: 1320px;
  }
}

@media screen and (max-width: 769px) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .box {
    padding: 30px 0;
  }
}
.Paxium-scroll {
  display: flex;
  overflow: hidden;
  height: 2.625rem; /* Equivalent to 42px */
  width: calc(134px * 12);
  animation: Paxium-scroll 18s linear infinite;
}

@keyframes Paxium-scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-194px * 6));
  }
}

@media screen and (max-width: 768px) {
  .Paxium-scroll {
    height: 42px; /* Adjust height for smaller screens */
    width: calc(134px * 12);
    animation: Paxium-scroll-mobile 18s linear infinite;
  }

  @keyframes Paxium-scroll-mobile {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-134px * 6));
    }
  }
}


.road-card{
  position: relative;
  height: 350px;
  margin-right: 10px;
  width: 260px;
  background-repeat: round;
  background-image: url('/src/assets/road-map.png');

  
}
.swipper-wrapper{
  height: 300px;
  display: flex;


}

.content
{
  top: 90px;
  position: absolute;
  left: 45px;
   width: 200px;


}



.numimage {
  position: absolute;
  top: 10px;
  left: 20px;
  background-color: #e8fcae;
  color: black;
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 50px; /* Aligns text vertically in the center */
}


.custom-accordion .accordion-header {
  background-color: #FAFFEA; /* Replace with your desired color */
}

.custom-accordion .accordion-body {
  background-color: #FAFFEA; /* Replace with your desired color */
}

.feature_tokenimage{
  position: absolute;
  width: 150px;
  z-index: 1;
  right: 25px;
  bottom: 100px;  

  
   
}




.imgaemap{

 background-image: url('/src/assets/road-map.png');
  background-color: transparent;
  background-repeat: no-repeat;
}
.Card-containor {
  flex: 0 0 ;
  width: 25%;
}



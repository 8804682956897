.Paxium-container {
    padding: 1rem 0;
    background-color: rgba(251, 244, 223, 1) /* Replace with actual color value */
  }
  
  .Paxium-wrapper {

    margin: 0 auto;
    padding: 0 1.5rem;
    width: 100%;
  }
  
  .Paxium-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.25rem;
    width: 100%;
  }
  
  .Paxium-heading {
    flex: 1;
    text-align: center;
    font-size: 2.875rem;

    text-transform: uppercase;
    color: black;
    letter-spacing: 0.5px;
  }
  
  .Paxium-scroll-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .Paxium-scroll {
    display: flex;
    overflow: hidden;
    height: 2.625rem;
    width: calc(134px * 12);
  }
  
  .Paxium-item {
    min-width: 110px;
    margin: 0 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .Paxium-container {
      padding: 1rem 0;
    }
  
    .Paxium-heading {
      width: auto;
    }
  

  
    .Paxium-scroll {
      height: 62px;
      width: calc(194px * 12);
    }
  
    .Paxium-item {
      min-width: 170px;
    }
  }
  

  .feature-card{
    height: 100px;
    margin-top: 30px;
  }